import React, { FC } from 'react';
import Image from 'next/image';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';

import SectionLayout from '../layouts/SectionLayout';
import IMainProps from '../../interfaces/IMainProps';

const PricesSection: FC<IMainProps> = (props: IMainProps) => {
  const { locale } = props;
  const theme = useTheme();

  return (
    <SectionLayout id='PricesSection' minHeightSection={true} background={theme.palette.secondary.dark} backImage='url("price-scatter.svg")'>
      <Typography component={'h3'} variant={'h4'}>
        Цены
      </Typography>
      <Grid container direction='row' justifyContent='space-evenly' alignItems='stretch' spacing={{ xs: 3, md: 4 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: 'center', m: '-2rem' }}>
          <Image
            src='/prices.svg'
            alt='Prices'
            width={465}
            height={260}
            loading='lazy'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
        <Card sx={{height: '100%'}}>
            <CardHeader
              titleTypographyProps={{ variant: 'h5', component: 'h4' }}
              title={'Курс занятий по\xA0химии, биологии'}
              subheader={locale === 'ru-RU' ? 'Цены за 1 занятие / 60мин.' : 'Цены за 1 занятие / 90мин.'}
            />
            <CardContent sx={{ py: 0, px: 0 }}>
              <Typography component='ul' sx={{ listStyleType: 'none', px: '1rem', 'li': { fontSize: '1.2rem', mb: '0.6rem' } }}>
                <Typography component='li'>
                  <Typography component='p' variant='h6'>Школьный уровень:</Typography>
                  {locale === 'ru-RU' ? '800\xA0RUB\xA0(onLine)' : '50\xA0000\xA0SUM\xA0(onLine) '}
                  {locale === 'ru-RU' ? '' : '70\xA0000\xA0SUM(с\xA0выездом)'};
                </Typography>
                <Typography component='li'>
                  <Typography component='p' variant='h6'>Подготовка к экзамену в&nbsp;Лицей&nbsp;/&nbsp;ОГЭ:</Typography>
                  {locale === 'ru-RU' ? '1000\xA0RUB\xA0(onLine)' : '60\xA0000\xA0SUM\xA0(onLine) '}
                  {locale === 'ru-RU' ? '' : '80\xA0000\xA0SUM(с\xA0выездом)'};
                </Typography>
                <Typography component='li'>
                  <Typography component='p' variant='h6'>Абитуриентам(экзамен в&nbsp;Универ&nbsp;/&nbsp;ЕГЭ):</Typography>
                  {locale === 'ru-RU' ? '1200\xA0RUB \xA0(onLine)' : '70\xA0000\xA0SUM\xA0(onLine) '}
                  {locale === 'ru-RU' ? '' : '90\xA0000\xA0SUM(с\xA0выездом)'};
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card sx={{height: '100%'}}>
            <CardHeader
              titleTypographyProps={{ variant: 'h5' }}
              title='Разовое занятие по химии, биологии'
              subheader={locale === 'ru-RU' ? 'Цены за 1 занятие / 60мин.' : 'Цены за 1 занятие / 90мин.'}
            />
            <CardContent sx={{ py: 0, px: 0 }}>
              <Typography component='ul' sx={{ listStyleType: 'none', px: '1rem', 'li': { fontSize: '1.2rem', mb: '0.6rem' } }}>
                <Typography component='li'>
                  <Typography component='p' variant='h6'>Школьный уровень:</Typography>
                  {locale === 'ru-RU' ? '900\xA0RUB' : '60\xA0000\xA0SUM'}{'\xA0'}(onLine);
                </Typography>
                <Typography component='li'>
                  <Typography component='p' variant='h6'>Подготовка к экзамену в&nbsp;Лицей&nbsp;/&nbsp;ОГЭ:</Typography>
                  {locale === 'ru-RU' ? '1100\xA0RUB' : '70\xA0000\xA0SUM'}{'\xA0'}(onLine);
                </Typography>
                <Typography component='li'>
                  <Typography component='p' variant='h6'>Абитуриентам (экзамен&nbsp;в&nbsp;Универ&nbsp;/&nbsp;ЕГЭ):</Typography>
                  {locale === 'ru-RU' ? '1300\xA0RUB' : '80\xA0000\xA0SUM'}{'\xA0'}(onLine);
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SectionLayout>
  )
}
export default PricesSection;