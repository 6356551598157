import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

interface ISectionLayoutProps {
    id: string,
    background?: string,
    backImage?: string,
    minHeightSection: boolean,
    children: React.ReactNode
};

export default function SectionLayout({ children, id, background, backImage, minHeightSection }: ISectionLayoutProps) {

    return (
        <>
            <Box id={id} sx={{
                position: 'relative',
                top: '-56px',
            }} />
            <Container component='section' maxWidth='lg'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    py: '1rem',
                    backgroundColor: { background },
                    backgroundImage: backImage,
                    backdropFilter: 'blur(2px)',
                    backgroundSize: '100%',
                    borderRadius: '0.8rem',
                    mt: '1rem',
                    minHeight: minHeightSection ? '88vh' : undefined,
                }}
            >
                <Box sx={{
                    flexGrow: 1,
                }}>
                    {children as ISectionLayoutProps}
                </Box>
            </Container>
        </>
    )
}
