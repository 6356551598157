import React, { FC } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { useTheme } from '@mui/material/styles';

import SectionLayout from '../layouts/SectionLayout';
import IMainProps from '../../interfaces/IMainProps';

const UzWhyPrivateLessonsSection: FC<IMainProps> = (props: IMainProps) => {
  const theme = useTheme();

  return (
    <SectionLayout id='WhyPrivateLessonsSection' minHeightSection={false} background={theme.palette.secondary.dark} backImage='url("circle-scatter.svg")'>
      <Typography component={'h3'} variant={'h4'}>
        Почему занятия с репетитором лучше?
      </Typography>
      <Typography variant={'subtitle1'}>
        Занятия с репетитором, групповые занятия или видеокурсы?
      </Typography>
      <Grid container direction='row' justifyContent='space-around' alignItems='stretch' spacing={{ xs: 3, md: 4 }}>
        <Grid sx={{ mt: 3 }} item xs={12} sm={6} md={4} lg={4}>
        <Card sx={{height: '100%'}}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              title='Индивидуальные занятия'
              sx={{ pb: 2 }}
            />
            <CardContent sx={{ py: 0 }}>
              <Typography component='ul' sx={{ my: 2, listStyle: 'none', mx: 0, px: 0 }}>
                <Typography component='li'>+ Персональный темп и метод обучения;</Typography>
                <Typography component='li'>+ Тет-а-тет с преподавателем;</Typography>
                <Typography component='li'>+ Меньше стеснение у ученика;</Typography>
                <Typography component='li'>+ Индивидуальный график.</Typography>
              </Typography>
              <Divider variant='middle' />
              <Typography component='ul' sx={{ my: 2, listStyle: 'none', mx: 0, px: 0 }}>
                <Typography component='li'>- Стоимость занятия несколько выше;</Typography>
                <Typography component='li'>- Нет коллективного общения;</Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ mt: 3 }} item xs={12} sm={6} md={4} lg={4}>
        <Card sx={{height: '100%'}}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              title='Групповые занятия'
              sx={{ pb: 2 }}
            />
            <CardContent sx={{ py: 0 }}>
              <Typography component='ul' sx={{ my: 2, listStyle: 'none', mx: 0, px: 0 }}>
                <Typography component='li'>+ Стоимость занятий в группе дешевле;</Typography>
                <Typography component='li'>+ Конкуренция мотивирует;</Typography>
                <Typography component='li'>+ Командная работа;</Typography>
              </Typography>
              <Divider variant='middle' />
              <Typography component='ul' sx={{ my: 2, listStyle: 'none', mx: 0, px: 0 }}>
                <Typography component='li'>- Оплата вносится за большой срок;</Typography>
                <Typography component='li'>- Сложно подстроиться под график;</Typography>
                <Typography component='li'>- Время на занятия строго ограничено;</Typography>
                <Typography component='li'>- Стеснение из-за сверстников;</Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ mt: 3 }} item xs={12} sm={6} md={4} lg={4}>
          <Card sx={{height: '100%'}}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              title='Видеокурсы'
              sx={{ pb: 2 }}
            />
            <CardContent sx={{ py: 0 }}>
              <Typography component='ul' sx={{ my: 2, listStyle: 'none', mx: 0, px: 0 }}>
                <Typography component='li'>+ Дёшево;</Typography>
                <Typography component='li'>+ Нет ограничения на повторение;</Typography>
              </Typography>
              <Divider variant='middle' />
              <Typography component='ul' sx={{ my: 2, listStyle: 'none', mx: 0, px: 0 }}>
                <Typography component='li'>- Оплата вносится за весь курс;</Typography>
                <Typography component='li'>- Не всегда качественный контент;</Typography>
                <Typography component='li'>- Нет контроля успеваемости;</Typography>
                <Typography component='li'>- Не подкрепляется практикой;</Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SectionLayout>
  )
}
export default UzWhyPrivateLessonsSection;