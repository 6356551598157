import React, { FC } from 'react';
import NextLink from 'next/link';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import MainBlob from '../icons/blobs/MainBlob';
import SectionLayout from '../layouts/SectionLayout';
import IMainProps from '../../interfaces/IMainProps';


const HeroSection: FC<IMainProps> = (props: IMainProps) => {
    const { locale } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const appPhone = locale === 'ru-RU' ? process.env.appRuPhone : process.env.appUzPhone;


    return (
        <SectionLayout id='HeroSection' minHeightSection={true} background={theme.palette.primary.dark}>
                <Grid direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }} justifyContent={matches === false ? 'center' : 'space-evenly'} alignItems='center' container spacing={{ xs: 1, md: 3 }} sx={{ minHeight: '94vh' }}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography
                            variant='h4'
                            component='h1'
                            sx={{ color: theme.palette.secondary.main }}
                        >
                            {process.env.personName}
                        </Typography>
                        <Typography
                            variant='subtitle1'
                            component='h2'
                            mb='1rem'
                            sx={{ color: theme.palette.primary.contrastText }}
                        >
                            {locale === 'ru-RU' ? 'Репетитор по химии и\xA0биологии онлайн' : 'Репетитор по химии и биологии в\xA0городе\xA0Самарканд'}
                        </Typography>
                        <Typography
                            variant='body1'
                            component='p'
                        >
                            {locale === 'ru-RU' ? 'Вы планируете поступать в\xA0медицинский\xA0институт?' : 'Вы абитуриент и готовитесь к\xA0поступлению в\xA0мед.\xA0университет?'}

                        </Typography>
                        <Typography
                            variant='body1'
                            component='p'
                        >
                            {locale === 'ru-RU' ? 'Или в поисках репетитора по химии, биологии для\xA0Вашего\xA0ребенка?' : 'А может у Вашего ребенка сложности по\xA0химии или\xA0биологии в\xA0школе?'}
                        </Typography>
                        <Typography
                            variant='body1'
                            component='p'
                        >
                            {locale === 'ru-RU' ? 'А может Вам просто нравится химия и\xA0биология,\xA0как\xA0и\xA0мне?' : 'Или Вам просто нравится химия и\xA0биология,\xA0так\xA0же,\xA0как\xA0мне?'}
                        </Typography>
                        <Typography
                            my='1rem'
                            variant='body1'
                            component='p'
                        >
                            {locale === 'ru-RU' ? 'Просто позвоните мне:' : 'Просто свяжитесь со мной:'}
                        </Typography>
                        <Stack direction='row' spacing={2}>
                            <NextLink href='#AboutUsSection' passHref>
                                <Button
                                    variant='outlined'
                                    color='secondary'
                                    startIcon={<AccountBoxOutlinedIcon />}
                                >
                                    Обо мне
                                </Button>
                            </NextLink>
                            <Button
                                href={matches === false ? `https://wa.me/${(appPhone as string).replace(/[^+0-9]/g, '')}` : '#ContactsSection'}
                                variant='contained'
                                color='secondary'
                                startIcon={matches === false ? <WhatsAppIcon /> : <ContactPhoneOutlinedIcon />}
                            >
                                {matches === false ? 'Позвонить' : 'Контакты'}
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{ textAlign: 'center', mb: matches === false ? '-20px' : undefined }}>
                        <MainBlob width='100%' color={theme.palette.secondary.dark} />
                    </Grid>
                </Grid>
        </SectionLayout >
    )
}
export default HeroSection;