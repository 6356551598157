import React, { FC } from 'react';

import Image from 'next/image';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import SectionLayout from '../layouts/SectionLayout';
import IMainProps from '../../interfaces/IMainProps';
import { CardMedia } from '@mui/material';

const ServicesSection: FC<IMainProps> = (props: IMainProps) => {
  const { locale } = props;
  const theme = useTheme();

  return (
    <SectionLayout id='ServicesSection' minHeightSection={true} background={theme.palette.primary.dark} backImage={'linear-gradient(90deg, #252525, #252525)'}>
      <Typography component={'h3'} variant={'h4'} gutterBottom>
        Услуги
      </Typography>
      <Grid container spacing={{ xs: 3, md: 4 }} direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card sx={{ mt: 2 }}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6', color: theme.palette.secondary.light }}
              title={locale === 'ru-RU' ? 'Курсовые занятия' : 'Курс занятий / Подготовка к\xA0экзаменам'}
              sx={{ pb: 0 }}
            />
            <CardContent sx={{ py: 0, my: 0 }}>
              <Typography component='ul'>
                <Typography component='li'>{locale === 'ru-RU' ? 'OnLine занятия;' : 'OnLine или OffLine занятия по\xA0выбору;'}</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'От двух занятий в\xA0неделю;' : 'Назначение необходимого количества часов в\xA0неделю;'}</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'Индивидуальный график;' : 'Согласование графика занятий;'}</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'Эффективная подача материала;' : 'Опросы, тестирование, самостоятельные;'}</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'Контроль успеваемости (Тесты, контрольные, опросы);' : ' Подготовка к поступлению в\xA0вузы\xA0РФ;'}</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? `Материалы по\xA0ОГЭ,\xA0ЕГЭ (ФИПИ\xA0${new Date().getFullYear()}г.)` : 'Химические опыты, работа с\xA0микроскопом\xA0и\xA0т.д.'}</Typography>
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6', color: theme.palette.warning.main }}
              title={locale === 'ru-RU' ? 'Разовые занятия' : 'Разовые занятия / Домашние задания'}
              sx={{ pb: 0 }}
            />
            <CardContent sx={{ py: 0, my: 0 }}>
              <Typography component='ul'>
                <Typography component='li'>OnLine занятие;</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'Объяснение требуемой темы в\xA0мессенджерах;' : 'Объяснение темы голосовыми сообщениями;'}</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'Консультации по теме в удобное для\xA0Вас\xA0время;' : 'Разбор вопросов по\xA0теме;'}</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'Назначение, разбор домашнего задания.' : 'Домашние задания.'}</Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ m: 'auto', textAlign: 'center' }}>
          {/* <Image
            src='/services.svg'
            alt='Services'
            width={360}
            height={335}
            loading='lazy'
          /> */}
          <CardMedia sx={{
            '& video': {
              borderRadius: 1,
            }
          }}>
            <video width={'100%'} height={'100%'} title='Репетитор по химии и биологии Мухамеджанова Евгения' poster='/videos/mukhamedjanova.png' controls={true} preload='none'>
              <source type='video/mp4' src='/videos/video_mukhamedjanova.mov' />
              <source type='video/mp4' src='/videos/video_mukhamedjanova.mp4' />
            </video>
          </CardMedia>
        </Grid>
      </Grid>
    </SectionLayout>
  )
}
export default ServicesSection;