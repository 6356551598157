import React, { FC } from 'react';

import Image from 'next/image';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import SectionLayout from '../layouts/SectionLayout';
import IMainProps from '../../interfaces/IMainProps';
import SocialStack from '../widgets/SocialStack';
import ContactStack from '../widgets/ContactStack';

const ContactsSection: FC<IMainProps> = (props: IMainProps) => {
  const { locale } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const appPhone = locale === 'ru-RU' ? process.env.appRuPhone : process.env.appUzPhone;
  const telegram = locale === 'ru-RU' ? process.env.appRuTelegram : process.env.appUzTelegram;

  return (
    <SectionLayout id='ContactsSection' minHeightSection={true} background={theme.palette.primary.dark} backImage={'linear-gradient(90deg, #252525, #252525)'}>
      <Grid container direction='row' justifyContent='space-evenly' alignItems='center' spacing={{ xs: 3, md: 4 }}>
        <Grid item xs={6} sm={6} md={4} lg={3} sx={{ textAlign: 'center' }}>
          <Image
            src={locale === 'ru-RU' ? '/ru-contacts.svg' : '/uz-contacts.svg'}
            alt='Contacts'
            width={360}
            height={640}
            loading='lazy'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader
              titleTypographyProps={{ component:'h3', variant:'h4', color: theme.palette.secondary.light }}
              title='Контакты'
            />
            <CardContent>
              <ContactStack locale={locale} fontSizeStack={'1.2rem'} />
            </CardContent>
          </Card>
          <SocialStack pyStack={6} spacingStack={2} sizeIcon={matches === false ? 35 : 40} justifyContentStack={'center'} appPhone={appPhone as string} telegram={telegram as string} />
        </Grid>
      </Grid>
    </SectionLayout>
  )
}
export default ContactsSection;