import React, { FC } from 'react';
import Image from 'next/image';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import BiotechIcon from '@mui/icons-material/Biotech';
import SchoolIcon from '@mui/icons-material/School';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import PangenesIcon from '../icons/social/PangenesIcon';


import SectionLayout from '../layouts/SectionLayout';

import IMainProps from '../../interfaces/IMainProps';


const AboutUsSection: FC<IMainProps> = (props: IMainProps) => {
  const { locale } = props;
  const theme = useTheme();

  return (
    <SectionLayout id='AboutUsSection' minHeightSection={true} background={theme.palette.secondary.dark} backImage='url("polygon-scatter.svg")'>
      <Grid container direction='row' justifyContent='space-evenly' alignItems='center' spacing={{ xs: 3, md: 4 }}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Image
            src='/about.svg'
            alt='About us'
            width={540}
            height={500}
            loading='lazy'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Card>
            <CardHeader
              titleTypographyProps={{ component: 'h3', variant: 'h4', }}
              title='Обо мне'
              sx={{ pb: 0 }}
            />
            <CardContent sx={{ py: 0 }}>
              <Typography component='ul' sx={{ pt: '1rem', }}>
                <Typography component='li'>
                  {locale === 'ru-RU' ? 'В 2009 окончила СГУ по\xA0специальности генетик;' : 'Окончила Самаркандский Государственный Университет в\xA02009 году по\xA0специальности генетик;'}
                </Typography>
                <Typography component='li'>
                  {locale === 'ru-RU' ? '2009 - 2014 работала преподавателем в\xA0средней образовательной школе;' : 'Преподавала в средней образовательной школе с\xA02009\xA0по\xA02014\xA0год (г.Самарканд);'}
                </Typography>
                <Typography component='li'>
                  {locale === 'ru-RU' ? 'С 2014 занимаюсь индивидуальной подготовкой учеников по\xA0химии и\xA0биологии;' : 'С 2014 по 2021 занималась репетиторством в\xA0РФ\xA0(г.Москва) по\xA0предметам биология и\xA0химия;'}
                </Typography>
                <Typography component='li'>
                  {locale === 'ru-RU' ? `С 2016 по ${new Date().getFullYear()} профессионально подготавливаю к\xA0ОГЭ,\xA0ЕГЭ.` : 'С 2016 года	и по настоящее время профессионально подготавливаю учеников к\xA0ЕГЭ\xA0и\xA0ОГЭ.'}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='space-around' alignItems='stretch' spacing={{ xs: 3, md: 4 }} sx={{ pt: '20px' }}>
        <Grid sx={{ mt: 4 }} item xs={12} sm={6} md={4} lg={4}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              avatar={
                <Avatar sx={{ bgcolor: theme.palette.warning.main, width: 56, height: 56 }}>
                  {locale === 'ru-RU'
                    ?
                    <PangenesIcon size={34} />
                    :
                    <BiotechIcon fontSize='large' />
                  }
                </Avatar>
              }
              title={locale === 'ru-RU' ? 'Авторский блог' : 'Лаб. оборудование'}
              sx={{ pb: 2 }}
            />
            <CardContent sx={{ py: 0 }}>
              {locale === 'ru-RU'
                ?
                  <Typography>
                    <Link
                      aria-label='pangenes.ru'
                      target='_blank'
                      component='a'
                      href='https://pangenes.ru'
                      sx={{
                        textDecoration: 'underline',
                        color: theme.palette.primary.contrastText,
                        cursor: 'pointer',
                        '&:hover': {
                          color: theme.palette.warning.main,
                        }
                      }}
                    >
                      Pangenes.ru
                    </Link> - мой блог по химии и&nbsp;биологии с&nbsp;интересными статьями, полезными материалами, а&nbsp;так&nbsp;же примеры решения задач для&nbsp;подготовки к&nbsp;ЕГЭ,&nbsp;ОГЭ.
                  </Typography>
                :
                <Typography component='ul'>
                  <Typography component='li'>Микроскоп и набор препаратов</Typography>
                  <Typography component='li'>Чашки Петри и пробирки для&nbsp;опытов</Typography>
                  <Typography component='li'>Набор химических реагентов</Typography>
                </Typography>
              }
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ mt: 4 }} item xs={12} sm={6} md={4} lg={4}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              avatar={
                <Avatar sx={{ backgroundColor: theme.palette.warning.main, width: 56, height: 56 }}>
                  <SchoolIcon fontSize='large' />
                </Avatar>
              }
              title={locale === 'ru-RU' ? 'Результативность' : 'Работаю на результат'}
              sx={{ pb: 2 }}
            />
            <CardContent sx={{ py: 0 }}>
              <Typography>Мои ученики успешно поступили в&nbsp;вузы:</Typography>
              <Typography component='ul'>
                <Typography component='li'>Сеченовский Университет</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'Темирязевская Академия' : 'МГМСУ им. А.И.Евдокимова'}</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'Академия имени К.И.Скрябина' : 'СамГУ'}</Typography>
                <Typography component='li'>{locale === 'ru-RU' ? 'РХТУ им. Д.И. Менделеева' : 'РУДН'}</Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ mt: 4 }} item xs={12} sm={6} md={4} lg={4}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              avatar={
                <Avatar sx={{ bgcolor: theme.palette.warning.main, width: 56, height: 56 }}>
                  <SupervisorAccountIcon fontSize='large' />
                </Avatar>
              }
              title={locale === 'ru-RU' ? 'Персональный подход' : 'Индивидуальный подход'}
              sx={{ pb: 2 }}
            />
            <CardContent sx={{ py: 0 }}>
              {locale === 'ru-RU'
                ? (
                  <Typography>
                    Для того чтобы достичь цели, я учитываю индивидуальные особенности каждого ученика и&nbsp;добиваюсь наилучшего результата!
                  </Typography>
                )
                :
                (
                  <Typography>
                    Разнообразие материалов и&nbsp;вариантов объяснения&nbsp;—&nbsp;залог того, что тему освоит любой ученик вне&nbsp;зависимости от&nbsp;уровня подготовки.
                  </Typography>
                )
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SectionLayout >
  )
}
export default AboutUsSection;