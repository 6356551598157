import React, { FC } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';

import SectionLayout from '../layouts/SectionLayout';
import IMainProps from '../../interfaces/IMainProps';

const RuWhyPrivateLessonsSection: FC<IMainProps> = (props: IMainProps) => {
  const theme = useTheme();

  return (
    <SectionLayout id='WhyPrivateLessonsSection' minHeightSection={false} background={theme.palette.secondary.dark} backImage='url("circle-scatter.svg")'>
      <Typography component={'h3'} variant={'h4'}>
        Почему индивидуальные занятия лучше?
      </Typography>
      <Typography variant={'subtitle1'}>
        Занятия с репетитором, групповые занятия или видеокурсы - что лучше для изучения химии и биологии?
      </Typography>
      <Card sx={{ mt: 3}}>
        <CardContent sx={{ textIndent: '2rem' }}>
          <Typography>
            Мой опыт позволяет уверить Вас, что&nbsp;индивидуальный подход к&nbsp;обучению превосходит любые другие формы обучения!
          </Typography>
          <Typography>
            Во-первых, индивидуальные занятия, хороши тем, что&nbsp;всё внимание преподавателя направлено на&nbsp;одного студента. Репетитор строит работу в&nbsp;соответствии с&nbsp;потребностями ученика, тем&nbsp;самым подобный подход помогает более эффективно планировать урок, так&nbsp;как он исходит из&nbsp;персонального темпа усвоения материала. Согласитесь, ведь&nbsp;у&nbsp;каждого человека свой уровень внимания, сосредоточенности и&nbsp;так&nbsp;далее.
          </Typography>
          <Typography>
            Во-вторых, практически все ученики,&nbsp;когда они ещё не&nbsp;вполне уверенно владеют знаниями, в&nbsp;занятиях с&nbsp;глазу на&nbsp;глаз проще справляются со&nbsp;своим стеснением и&nbsp;раскрывают свои способности, чем&nbsp;в&nbsp;большой группе учеников. В&nbsp;своей практике я часто замечаю, что&nbsp;ребенок боится спросить какую&nbsp;-&nbsp;нибудь мелочь, что&nbsp;сложно заметить в&nbsp;большой группе.
          </Typography>
          <Typography>
            И в-третьих, индивидуальная форма обучения позволяет студенту выбрать удобное для&nbsp;него расписание и&nbsp;длительность самих занятий, что&nbsp;очень важно наше время.
          </Typography>
          <Typography>
            Что касается видеокурсов, то&nbsp;при&nbsp;данном виде обучения нет контроля успеваемости, информация никак не&nbsp;подкрепляется практикой, школьники даже не&nbsp;делают конспекты. Не&nbsp;спорю, видео хорошо подходит для&nbsp;демонстрации экспериментов: позволяет сэкономить на&nbsp;дорогостоящем и&nbsp;сложном оборудовании, способно раз&nbsp;за&nbsp;разом демонстрировать то, что&nbsp;требует сложных условий для повторения, но&nbsp;это&nbsp;всего лишь инструмент преподавателя. На&nbsp;своих занятиях я прибегаю к&nbsp;его&nbsp;помощи, что&nbsp;бы&nbsp;показывать эксперименты или&nbsp;опыты безопасно и&nbsp;наглядно для&nbsp;учеников.
          </Typography>
        </CardContent>
      </Card>
    </SectionLayout>
  )
}
export default RuWhyPrivateLessonsSection;