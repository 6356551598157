import { FC } from 'react';

interface IconProps {
    width: string,
    color: string,
}

const MainBlob: FC<IconProps> = (props: IconProps) => {
    const { width, color } = props;
    return (
        <svg
            viewBox={`0 0 500 500`}
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            fill={color}
        >
            <path
                id='blob'
                d='M430,329.5Q409,409,329.5,432Q250,455,167,435.5Q84,416,48,333Q12,250,53.5,172.5Q95,95,172.5,72.5Q250,50,336.5,63.5Q423,77,437,163.5Q451,250,430,329.5Z'
            >
                <animate attributeName='d' dur='5s' repeatCount='indefinite'
                    values='
                    M430,329.5Q409,409,329.5,432Q250,455,167,435.5Q84,416,48,333Q12,250,53.5,172.5Q95,95,172.5,72.5Q250,50,336.5,63.5Q423,77,437,163.5Q451,250,430,329.5Z;
                    M413,325Q400,400,325,416Q250,432,169.5,421.5Q89,411,53.5,330.5Q18,250,62,178Q106,106,178,90.5Q250,75,336,76.5Q422,78,424,164Q426,250,413,325Z;
                    M430,329.5Q409,409,329.5,432Q250,455,167,435.5Q84,416,48,333Q12,250,53.5,172.5Q95,95,172.5,72.5Q250,50,336.5,63.5Q423,77,437,163.5Q451,250,430,329.5Z;
                '
                />
            </path>
            <image x='1' y='60' width='160' height='70' href='/ms1.svg' />
            <image x='380' y='110' width='120' height='60' href='/ms2.svg' />
            <image x='0' y='370' width='160' height='60' href='/ms3.svg' />
            <image x='100' y='100' width='300' height='300' href='/science.svg' />
        </svg>
    );
}

export default MainBlob;


