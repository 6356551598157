import React, { FC } from 'react';
import Image from 'next/image'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import SectionLayout from '../layouts/SectionLayout';
import { Box } from '@mui/system';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import IMainProps from '../../interfaces/IMainProps';

interface IReviewItem {
  name: string,
  avatar: string,
  description: string,
  date: string
}

const ReviewsSection: FC<IMainProps> = (props: IMainProps) => {
  const { locale } = props;
  const theme = useTheme();


  const reviewItems: IReviewItem[] = [
    {
      name: 'Анжелика Арманова',
      avatar: '0d4a5648-9a3c-4027-b838-2582faeb5c12.jpg',
      description: 'Занимались с Евгенией перед поступлением в РУДН. Уверенно могу сказать, что более профессионального репетитора я бы не нашла. За несколько месяцев удалось подтянуть знания и подготовиться к вступительному экзамену.',
      date: '22.08.2021'
    },
    {
      name: 'Денис',
      avatar: '0f7c7a43-1e1d-4f64-9799-e9653ed5118a.jpg',
      description: `Евгения Искандеровна - лучший репетитор! Провели 2 занятия по химии. Не понимал тему, но после занятия всё оказалось не так страшно. Она мне очень помогла!`,
      date: '15.07.2021'
    },
    {
      name: 'Юлия Викторовна',
      avatar: '3d8b52ed-443e-4aee-ab05-f99ba55f052b.jpg',
      description: `Евгения, моя дочка с огромным удовольствием у Вас занимается! Спасибо за то, что Вы внимательно и терпеливо относитесь к моему ребенку. Ваша заслуга, что дочка стала любить химию и биологию!`,
      date: '05.11.2020'
    },
    {
      name: 'Андрей Викторович',
      avatar: '46aaa4b4-ba44-4500-9368-7cbe9c0a26ae.jpg',
      description: `Спасибо огромное за конструктивный подход, великолепное понимание персональных характеристик ученика, открытость и корректность. Евгения - Вы молодец!`,
      date: '11.08.2020'
    },
    {
      name: 'Айзерэ',
      avatar: '46d5414b-0b09-45a5-9b0c-943a19f0cc7b.jpg',
      description: `Очень благодарна за объясниение сложных тем по органике. Евгения Искандеровна спасибо Вам огромное!`,
      date: '03.03.2020'
    },
    {
      name: 'Миша',
      avatar: '192a1bb9-f702-49e6-93fd-b0e722e69134.jpg',
      description: 'Супер учитель, все объясняет просто отлично, после каждого занятия горы положительных эмоций)) Главное, если что-то не понимаешь спрашивать, а не сидеть и тупить!',
      date: '24.08.2019'
    },
    {
      name: 'Настя',
      avatar: '241d58ab-24d8-485b-8aa0-9ca34bd72ddf.jpg',
      description: 'Мой любимый учитель!!! Рекомендую всем!!! кто не любит занудных уроков - особенно)))',
      date: '17.05.2019'
    },
    {
      name: 'Артем',
      avatar: '755e3619-6d08-4fe7-8769-b1dad2cb9b8f.jpg',
      description: `Евгения Искандеровна отлично объясняет материал. Рекомендую всем, кто столкнулся с ЕГЭ. Хочется сказать огромное спасибо за терпение и результативность.`,
      date: '14.03.2019'
    },
    {
      name: 'Паша',
      avatar: '262764ac-9fd2-4a29-95f0-6d2a609ccdee.jpg',
      description: `Поступил в РостГМУ за три месяца подготовки. Евгения Искандеровна, огромное спасибо! Вы лучшая!`,
      date: '12.02.2019'
    },
    {
      name: 'Елена Дмитриевна',
      avatar: 'e6d1c442-71b7-4a85-be05-1189e00d7bba.jpg',
      description: `Спасибо, Евгения Искандеровна, что смогли найти время в своем плотном расписании и позаниматься с моим сыном. Урок очень наполненный. Успели все: повторить необходимые формулы, порешали задачи на закрепление.`,
      date: '28.08.2018'
    },
    {
      name: 'Раиса Тихоновна',
      avatar: 'e66db514-e17a-4526-968a-ff751ce4bbe7.jpg',
      description: 'Отличный репетитор, девочкам заниматься понравилось, оценки улучшились.',
      date: '30.04.2018'
    },
    {
      name: 'Анастасия Викторовна',
      avatar: '45241cc3-632b-4182-bdef-53951d892a50.jpg',
      description: 'Саша поступил! Спасибо! Порекомендовала Вас знакомым, хотели позвонить.',
      date: '26.04.2018'
    }
  ];

  if (locale === 'ru-RU') {
    reviewItems.reverse();
  }

  reviewItems.reverse();


  const Item = (props: IReviewItem) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Card sx={{ height: '100%' }}>
          <CardHeader
            titleTypographyProps={{ variant: 'h6', color: theme.palette.primary.contrastText }}
            avatar={
              <Avatar sx={{ bgcolor: theme.palette.secondary.main, width: 70, height: 70 }} aria-label='Enrollee'>
                <Image
                  src={`/avatars/${props.avatar}`}
                  alt={props.name}
                  width={70}
                  height={70}
                />
              </Avatar>
            }
            title={props.name}
            subheader={props.date}
          />
          <CardContent>
            {props.description}
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const [checked, setChecked] = React.useState(false);
  const [checkedOne, setCheckedOne] = React.useState(true);
  const [checkedTwo, setCheckedTwo] = React.useState(false);

  const handleClick = () => {
    setChecked(() => {
      if (checkedOne) {
        setCheckedOne(!checkedOne);
      } else {
        setTimeout(() => {
          setCheckedOne(!checkedOne);
        }, 290);
      }
      if (!checkedTwo) {
        setTimeout(() => {
          setCheckedTwo(!checkedTwo);
        }, 290);
      } else {
        setCheckedTwo(!checkedTwo);
      }
      return !checked;
    });

  };


  return (
    <SectionLayout id='ReviewsSection' minHeightSection={true} backImage='url("blurry-gradient.svg")'>
      <Typography component={'h3'} variant={'h4'}>
        Отзывы
      </Typography>
      <Box sx={{minHeight:'90vh'}}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <IconButton size='small' aria-label='left' disabled={checkedOne} onClick={handleClick}>
              <ArrowLeftIcon fontSize='large' />
            </IconButton>
            <IconButton size='small' aria-label='right' disabled={checkedTwo} onClick={handleClick}>
              <ArrowRightIcon fontSize='large' />
            </IconButton>
          </Stack>
        </Box>
        <Fade
          in={checkedOne}
          mountOnEnter
          unmountOnExit
          {...(checkedOne ? { timeout: 1000 } : {})}
        >
          <Grid container direction='row' justifyContent='space-evenly' alignItems='stretch' spacing={{ xs: 3, md: 4 }}>
            {reviewItems.slice(0, reviewItems.length / 2).map((review: IReviewItem) => {
              return <Item key={review.avatar}  {...review} />
            })}
          </Grid>
        </Fade>
        <Fade
          in={checkedTwo}
          mountOnEnter
          unmountOnExit
          {...(checkedTwo ? { timeout: 1000 } : {})}
        >
          <Grid container direction='row' justifyContent='space-evenly' alignItems='stretch' spacing={{ xs: 3, md: 4 }}>
            {reviewItems.slice(reviewItems.length / 2).map((review: IReviewItem) => {
              return <Item key={review.avatar} {...review} />
            })}
          </Grid>
        </Fade>
      </Box>
    </SectionLayout>
  )
}
export default ReviewsSection;
