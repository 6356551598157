import type { NextPage } from 'next'
import { GetServerSideProps } from 'next';

import MainLayout from '../components/layouts/MainLayout';

import HeroSection from '../components/sections/HeroSection';
import AboutUsSection from '../components/sections/AboutUsSection';
import ServicesSection from '../components/sections/ServicesSection';
import PricesSection from '../components/sections/PricesSection';
import ContactsSection from '../components/sections/ContactsSection';
import ReviewsSection from '../components/sections/ReviewsSection';
import RuWhyPrivateLessonsSection from '../components/sections/RuWhyPrivateLessonsSection';
import UzWhyPrivateLessonsSection from '../components/sections/UzWhyPrivateLessonsSection';

import IMainProps from '../interfaces/IMainProps'


const Home: NextPage<IMainProps> = (props: IMainProps) => {
  const { locale } = props;
  return (
    <MainLayout
      title={`Репетитор по химии и биологии ${locale === 'ru-RU' ? 'онлайн.' : 'в г.Самарканд.'}`}
      description={locale === 'ru-RU'
        ?
        `Я репетитор по химии и биологии, и я понимаю все, о чем вы переживаете, так как профессионально занимаюсь репетиторством более 6 лет, и уже помогла многим ученикам и абируриентам с подготовкой. Преподаю онлайн по всей России. Провожу курсовые занятия по подготовке к ОГЭ, ЕГЭ с домашним заданием и контролем усвоения материала, а также разовые занятия, где обьясняю необходимую тему. Просто свяжитесь со мной по т.${process.env.appRuPhone}.`
        :
        `Я репетитор по биологии и химии в г. Самарканд. Занимаюсь репетиторством более 7 лет, есть опыт работы в России(6 лет г. Москва). Мои занятия отличаются тем, что я отрабатываю с учеником каждую тему на примере заданий ЕГЭ последних лет, закрепляю материал домашним заданием и контролирую усвоение материала учеником.  Провожу курсовые занятия или разовые занятия, где обьясняю необходимую тему. Просто звоите по т. ${process.env.appRuPhone}.`
      }
      keywords={`репетитор, онлайн, ${locale === 'ru-RU' ? 'россия, ОГЭ, ЕГЭ' : ' офлайн, самарканд, узбекистан'}, химия, биология, школа, вуз, абитуриенту, подготовка к экзамену по химии, подготовка к экзамену по биологии `}
      props={props}
    >
      <HeroSection {...props} />
      <AboutUsSection {...props} />
      <ServicesSection {...props} />
      <PricesSection {...props} />
      <ContactsSection {...props} />
      {
        locale === 'ru-RU'
          ? <RuWhyPrivateLessonsSection {...props} />
          : <UzWhyPrivateLessonsSection {...props} />
      }
      <ReviewsSection {...props} />
    </MainLayout>
  )
}


export default Home


export const getServerSideProps: GetServerSideProps = async (context) => {

  const clientLocale = context.locale;
  const locale = clientLocale ? clientLocale : null;

  return { props: { locale } }
}
